@import url("https://fonts.googleapis.com/css2?family=Barlow+Semi+Condensed:wght@600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap");
body {
  /* background-color: #a9a9a9; */
  background-color: #fafaf5;
  width: 100vw;
  overflow-x: hidden;
}

html {
  width: 100vw;
  overflow-x: hidden;
}

.nav-title {
  font-family: "Barlow Semi Condensed" !important;
  font-size: 24pt !important;
  letter-spacing: 2px !important;
  word-spacing: 6px !important;
  margin-top: -5px !important;
  text-decoration: none;
  color: white;
  opacity: 0.9;
}

.nav-title:hover {
  cursor: pointer;
  opacity: 1;
}

.nav-bar {
  background-color: #5b1b0b !important;
  width: 100vw;
  overflow-x: hidden;
}

.drawer-container h1,
.drawer-container h2 {
  padding-left: 20px;
  font-family: "Barlow Semi Condensed";
}

.drawer-container h1 {
  margin-top: 40px;
  margin-bottom: 5px;
}

.drawer-container .menu-icon-container {
  min-width: 40px;
  margin-left: 15px;
}

.home {
  padding-top: 110px;
  padding-bottom: 50px;
  width: 100vw;
  overflow-x: hidden;
}

.home .filter-container {
  margin-top: 30px;
  padding: 15px 30px;
  margin-bottom: 30px;
  position: fixed;
  left: 50%;
  top: 45px;
  margin-left: -380px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 700px;
  -webkit-transform: scale(0.8);
  transform: scale(0.8);
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  background-color: white;
  z-index: 999;
}

.home .filter-container .filter-input-container {
  margin-right: 10px;
}

.home .filter-container .filter-input-container button {
  -webkit-transform: scale(1.5);
  transform: scale(1.5);
  margin-right: 6px;
}

.home .filter-container .filter-input-container .filter-input {
  background-color: white;
}

.home .filter-container .apply-btn {
  height: 56px;
  padding-left: 25px;
  padding-right: 25px;
  background-color: #5b1b0b;
  color: white;
  font-family: "Roboto";
}

.home .filter-container .apply-btn:hover {
  opacity: 0.9;
}

.home .load-btn {
  margin-top: 50px;
}

.post-container {
  position: relative;
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  border: none !important;
  width: 600px;
  background-color: white;
  padding: 20px;
  padding-bottom: 0px;
}

.post-container .post-title {
  font-size: 28pt;
  font-family: "Barlow Semi Condensed";
  margin-top: 60px;
  width: 90%;
  text-overflow: ellipsis;
}

.post-container .user {
  position: absolute;
  top: 10px;
  left: 0;
  width: 100%;
}

.post-container .user .prof-pic {
  position: absolute;
  left: 10px;
}

.post-container .user .prof-pic img {
  width: 100%;
  height: 100%;
}

.post-container .user p {
  width: 50%;
  position: absolute;
  left: 60px;
  text-align: left;
  margin-top: 2px;
  font-weight: 600;
  font-size: 12pt;
  font-family: "Roboto";
}

.post-container .post-date {
  position: absolute;
  top: 19px;
  left: 60px;
  font-weight: 600;
  color: rgba(20, 20, 20, 0.4);
  font-size: 10pt;
}

.post-container .tags {
  -webkit-transform: scale(0.8);
  transform: scale(0.8);
  margin-top: -20px;
  font-family: "Roboto";
  font-weight: 600;
}

.post-container .tags span {
  color: rgba(20, 20, 20, 0.4);
}

.post-container .likes {
  -webkit-transform: scale(0.8);
  transform: scale(0.8);
  position: absolute;
  top: 5px;
  right: 5px;
}

.likes .liked {
  border-color: #5b1b0b;
  background-color: #5b1b0b;
  color: white;
}

.likes .liked:hover {
  border-color: #5b1b0b;
  background-color: #5b1b0b;
  color: white;
  opacity: 0.8;
}

.likes .unliked {
  border-color: #5b1b0b;
  background-color: white;
  color: #5b1b0b;
}

.likes .unliked:hover {
  border-color: #5b1b0b;
  background-color: white;
  color: #5b1b0b;
  opacity: 0.6;
}

.post-page-container {
  padding-bottom: 70px;
}

.post-page-container .post-content-container {
  background-color: white;
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  margin-top: 40px;
  margin-bottom: 20px;
  padding-bottom: 20px;
  min-width: 1000px;
  width: 70%;
  position: relative;
}

.post-page-container .post-content-container a {
  word-break: break-all;
}

.post-page-container .post-content-container .likes {
  position: absolute;
  top: 10px;
  right: 10px;
}

.post-page-container .post-content-container .user {
  position: absolute;
  left: 20px;
}

.post-page-container .post-content-container .user .prof-pic {
  margin-top: 12px;
}

.post-page-container .post-content-container .user p {
  position: absolute;
  top: -2px;
  left: 50px;
  font-weight: 600;
  font-family: "Roboto";
}

.post-page-container .post-content-container .post-date {
  position: absolute;
  top: 20px;
  left: 70px;
  font-size: 11pt;
  opacity: 0.4;
}

.post-page-container .post-content-container .tags {
  margin-top: 10px;
  margin-bottom: 40px;
  font-weight: 600;
  opacity: 0.6;
}

.post-page-container .post-content-container h1 {
  font-family: "Barlow Semi Condensed";
  font-size: 45pt;
  margin: 0;
  margin-top: 50px;
}

.post-page-container .comments-container {
  min-width: 1000px;
  width: 70%;
}

.post-page-container .comments-container .comment {
  background-color: white;
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  width: 500px;
  padding: 60px 20px 0px 20px;
  position: relative;
  min-height: 80px;
}

.post-page-container .comments-container .comment .user {
  position: absolute;
  top: 5px;
  left: 0px;
}

.post-page-container .comments-container .comment .user .prof-pic {
  position: absolute;
  top: 3px;
  left: 8px;
}

.post-page-container .comments-container .comment .user .username {
  position: absolute;
  top: -11px;
  left: 56px;
  font-weight: 600;
}

.post-page-container .comments-container .comment .user .post-date {
  position: absolute;
  text-align: left;
  top: 12px;
  left: 57px;
  width: 200px;
  font-size: 10pt;
  opacity: 0.4;
}

.post-page-container .comments-container .comment-content {
  max-height: 200px;
  padding-bottom: 25px;
  overflow-y: scroll;
  width: 95%;
}

.post-page-container .comments-container .delete-btn {
  margin-right: -490px;
  margin-bottom: 5px;
}

.post-page-container .comments-container .input-box {
  padding: 10px;
  margin-top: 15px;
  width: 350px !important;
  border-radius: 4px;
  border: none;
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}

.post-page-container .comments-container .submit-btn {
  margin-top: 10px;
  margin-right: -268px;
  padding: 8px 25px;
  color: white;
  background-color: #5b1b0b;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.draft-editor-wrapper {
  border: 1px solid #ccc;
}

@media only screen and (max-width: 600px) {
  .filter-container {
    position: absolute !important;
    width: 110vw !important;
    margin-left: -50px !important;
    left: 0 !important;
    top: 20px !important;
    border-radius: 0 !important;
  }
  .home {
    padding-top: 75px;
  }
  .summary {
    margin-top: 10px !important;
  }
  .post-container {
    width: 90%;
    border-radius: 0 !important;
  }
  .post-container .user {
    left: 0px;
  }
  .post-container .post-date {
    left: 60px;
  }
  .post-title {
    font-size: 24pt !important;
    margin-bottom: 30px;
    margin-top: 45px !important;
  }
  .post-page-container {
    width: 100vw;
    overflow-x: hidden;
  }
  .post-page-container .post-content-container {
    width: 100vw;
    min-width: 100vw !important;
    overflow-x: hidden;
    margin-top: 5px;
  }
  .post-page-container .post-content-container h1 {
    width: 96%;
    margin-left: 2%;
    font-size: 30pt;
  }
  .post-page-container .post-content-container .likes {
    -webkit-transform: scale(0.7);
    transform: scale(0.7);
    right: 4px;
  }
  .post-page-container .post-content-container .post-date {
    left: 65px;
  }
  .post-page-container .post-content-container .user {
    left: 15px;
  }
  .post-page-container .comments-container {
    min-width: 100vw !important;
    width: 100vw;
    overflow-x: hidden;
  }
  .post-page-container .comments-container .comment {
    min-width: 100vw !important;
    width: 100vw;
    overflow-x: hidden;
  }
  .nav-bar {
    width: 100vw;
    overflow: hidden;
    padding-right: 10px;
  }
  .nav-bar .prof-pic {
    margin-right: -20px;
  }
  .nav-bar .prof-pic img {
    -webkit-transform: scale(0.7);
    transform: scale(0.7);
  }
  .nav-title {
    font-size: 17pt !important;
    margin-left: -10px !important;
  }
  .content-editor-wrapper {
    border-style: ridge;
  }
  .content-editor-text {
    border-style: ridge;
    background-color: white;
  }
}
