@import url("https://fonts.googleapis.com/css2?family=Barlow+Semi+Condensed:wght@600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap");

$header-font: "Barlow Semi Condensed";
$subheader-font: "Roboto";

$den-brown: rgb(91, 27, 11);
// $den-bg: rgb(250, 250, 245);
$den-bg: rgb(129, 133, 137);
$whitewhite: white;

body {
  background-color: $den-bg;
  width: 100vw;
  overflow-x: hidden;
}

html {
  width: 100vw;
  overflow-x: hidden;
}

.nav-title {
  font-family: $header-font !important;
  font-size: 24pt !important;
  letter-spacing: 2px !important;
  word-spacing: 6px !important;
  margin-top: -5px !important;
  text-decoration: none;
  color: $whitewhite;
  opacity: 0.9;
  &:hover {
    cursor: pointer;
    opacity: 1;
  }
}

.nav-bar {
  background-color: $den-brown !important;
  width: 100vw;
  overflow-x: hidden;
}

.drawer-container {
  h1,
  h2 {
    padding-left: 20px;
    font-family: $header-font;
  }
  h1 {
    margin-top: 40px;
    margin-bottom: 5px;
  }
  .menu-icon-container {
    min-width: 40px;
    margin-left: 15px;
  }
}

.home {
  padding-top: 110px;
  padding-bottom: 50px;
  width: 100vw;
  overflow-x: hidden;
  .filter-container {
    margin-top: 30px;
    padding: 15px 30px;
    margin-bottom: 30px;
    position: fixed;
    left: 50%;
    top: 45px;
    margin-left: -380px;
    display: flex;
    justify-content: center;
    width: 700px;
    transform: scale(0.8);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    background-color: $whitewhite;
    z-index: 999;
    .filter-input-container {
      margin-right: 10px;
      button {
        transform: scale(1.5);
        margin-right: 6px;
      }
      .filter-input {
        background-color: $whitewhite;
      }
    }
    .apply-btn {
      height: 56px;
      padding-left: 25px;
      padding-right: 25px;
      background-color: $den-brown;
      color: $whitewhite;
      font-family: $subheader-font;
      &:hover {
        opacity: 0.9;
      }
    }
  }
  .load-btn {
    margin-top: 50px;
  }
}

.post-container {
  position: relative;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  border: none !important;
  width: 600px;
  background-color: $whitewhite;
  padding: 20px;
  padding-bottom: 0px;
  .post-title {
    font-size: 28pt;
    font-family: $header-font;
    margin-top: 60px;
    width: 90%;
    text-overflow: ellipsis;
  }
  .user {
    position: absolute;
    top: 10px;
    left: 0;
    width: 100%;
    .prof-pic {
      position: absolute;
      left: 10px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    p {
      width: 50%;
      position: absolute;
      left: 60px;
      text-align: left;
      margin-top: 2px;
      font-weight: 600;
      font-size: 12pt;
      font-family: $subheader-font;
    }
  }
  .post-date {
    position: absolute;
    top: 19px;
    left: 60px;
    font-weight: 600;
    color: rgba(20, 20, 20, 0.4);
    font-size: 10pt;
  }
  .tags {
    transform: scale(0.8);
    margin-top: -20px;
    font-family: $subheader-font;
    font-weight: 600;
    span {
      color: rgba(20, 20, 20, 0.4);
    }
  }
  .likes {
    transform: scale(0.8);
    position: absolute;
    top: 5px;
    right: 5px;
  }
}

.likes {
  .liked {
    border-color: $den-brown;
    background-color: $den-brown;
    color: $whitewhite;
    &:hover {
      border-color: $den-brown;
      background-color: $den-brown;
      color: $whitewhite;
      opacity: 0.8;
    }
  }
  .unliked {
    border-color: $den-brown;
    background-color: $whitewhite;
    color: $den-brown;
    &:hover {
      border-color: $den-brown;
      background-color: $whitewhite;
      color: $den-brown;
      opacity: 0.6;
    }
  }
}

.post-page-container {
  padding-bottom: 70px;
  // padding-top: 50px;
  .post-content-container {
    background-color: $whitewhite;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    margin-top: 40px;
    margin-bottom: 20px;
    padding-bottom: 20px;
    min-width: 1000px;
    width: 70%;
    position: relative;
    a {
      word-break: break-all;
    }
    .likes {
      position: absolute;
      top: 10px;
      right: 10px;
    }
    .user {
      position: absolute;
      left: 20px;
      .prof-pic {
        margin-top: 12px;
      }
      p {
        position: absolute;
        top: -2px;
        left: 50px;
        font-weight: 600;
        font-family: $subheader-font;
      }
    }
    .post-date {
      position: absolute;
      top: 20px;
      left: 70px;
      font-size: 11pt;
      opacity: 0.4;
    }
    .tags {
      margin-top: 10px;
      margin-bottom: 40px;
      font-weight: 600;
      opacity: 0.6;
    }
    h1 {
      font-family: $header-font;
      font-size: 45pt;
      margin: 0;
      margin-top: 50px;
    }
  }
  .comments-container {
    min-width: 1000px;
    width: 70%;
    .comment {
      background-color: $whitewhite;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
      width: 500px;
      padding: 60px 20px 0px 20px;
      position: relative;
      min-height: 80px;
      .user {
        position: absolute;
        top: 5px;
        left: 0px;
        .prof-pic {
          position: absolute;
          top: 3px;
          left: 8px;
        }
        .username {
          position: absolute;
          top: -11px;
          left: 56px;
          font-weight: 600;
        }
        .post-date {
          position: absolute;
          text-align: left;
          top: 12px;
          left: 57px;
          width: 200px;
          font-size: 10pt;
          opacity: 0.4;
        }
      }
    }
    .comment-content {
      max-height: 200px;
      padding-bottom: 25px;
      overflow-y: scroll;
      width: 95%;
    }
    .delete-btn {
      margin-right: -490px;
      margin-bottom: 5px;
    }
    .input-box {
      padding: 10px;
      margin-top: 15px;
      width: 350px !important;
      border-radius: 4px;
      border: none;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    }
    .submit-btn {
      margin-top: 10px;
      margin-right: -268px;
      padding: 8px 25px;
      color: $whitewhite;
      background-color: $den-brown;
    }
  }
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.draft-editor-wrapper {
  border: 1px solid #ccc;
}

@media only screen and (max-width: 600px) {
  .filter-container {
    position: absolute !important;
    width: 110vw !important;
    margin-left: -50px !important;
    left: 0 !important;
    top: 20px !important;
    border-radius: 0 !important;
  }
  .home {
    padding-top: 75px;
  }
  .summary {
    margin-top: 10px !important;
  }
  .post-container {
    width: 90%;
    border-radius: 0 !important;
    .user {
      left: 0px;
    }
    .post-date {
      left: 60px;
    }
  }
  .post-title {
    font-size: 24pt !important;
    margin-bottom: 30px;
    margin-top: 45px !important;
  }
  .post-page-container {
    width: 100vw;
    overflow-x: hidden;
    .post-content-container {
      width: 100vw;
      min-width: 100vw !important;
      overflow-x: hidden;
      margin-top: 5px;
      h1 {
        width: 96%;
        margin-left: 2%;
        font-size: 30pt;
      }
      .likes {
        transform: scale(0.7);
        right: 4px;
      }
      .post-date {
        left: 65px;
      }
      .user {
        left: 15px;
      }
    }
    .comments-container {
      min-width: 100vw !important;
      width: 100vw;
      overflow-x: hidden;
      .comment {
        min-width: 100vw !important;
        width: 100vw;
        overflow-x: hidden;
      }
    }
  }
  .nav-bar {
    width: 100vw;
    overflow: hidden;
    padding-right: 10px;
    .prof-pic {
      margin-right: -20px;
      img {
        transform: scale(0.7);
      }
    }
  }
  .nav-title {
    font-size: 17pt !important;
    margin-left: -10px !important;
  }
}
