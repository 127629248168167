.addImage {
  background: #fff;
  display: inline-block;
}

.addImagePopover {
  margin-top: 10px;
  background: #fff;
  position: absolute;
  height: 54px;
  width: 300px;
  border-radius: 2px;
  padding: 10px;
  box-shadow: 0px 4px 30px 0px rgba(220, 220, 220, 1);
  z-index: 1000;
}

.addImageClosedPopover {
  display: none;
}

.addImageButton {
  box-sizing: border-box;
  background: #fff;
  border: 1px solid #ddd;
  padding: 0;
  color: #888;
  margin: 0;
  border-radius: 1.5em;
  cursor: pointer;
  height: 1.5em;
  width: 2.5em;
  font-size: 1.5em;
  line-height: 1.2em;
  margin: 0;
}

.addImageButton:focus {
  outline: 0; /* reset for :focus */
}

.addImageButton:hover {
  background: #f3f3f3;
}

.addImageButton:active {
  background: #e6e6e6;
}

.addImagePressedButton {
  composes: addImageButton;
  background: #ededed;
}

.addImageBottomGradient {
  width: 100%;
  height: 1em;
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-color: white;
  pointer-events: none;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 100%
  );
}

.addImageInput {
  box-sizing: border-box;
  border: 1px solid #ddd;
  cursor: text;
  padding: 4px;
  width: 78%;
  border-radius: 2px;
  margin-bottom: 1em;
  box-shadow: inset 0px 1px 8px -3px #ababab;
  background: #fefefe;
}

.addImageConfirmButton {
  box-sizing: border-box;
  background: #fff;
  border: 1px solid #ddd;
  padding: 0;
  color: #888;
  margin: 0;
  border-radius: 2.1em;
  cursor: pointer;
  height: 2.1em;
  width: 18%;
  font-size: 1em;
  line-height: 2.1em;
  margin: 0;
  margin-left: 4%;
}

.addImageConfirmButton:focus {
  outline: 0; /* reset for :focus */
}

.addImageConfirmButton:hover {
  background: #f3f3f3;
}

.addImageConfirmButton:active {
  background: #e6e6e6;
}
